import { default as _400oPo0k2s7zSMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/400.vue?macro=true";
import { default as _500bHxzSalFWMMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/500.vue?macro=true";
import { default as contatoP5bWUwSM7MMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/contato.vue?macro=true";
import { default as casas_45bahianzBQp2bdgDMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue?macro=true";
import { default as digioCEAIFQzkpdMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/credor/digio.vue?macro=true";
import { default as ponto8E3hPGe2G1Meta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/credor/ponto.vue?macro=true";
import { default as empresaCrM0duAwYWMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/empresa.vue?macro=true";
import { default as indexWFlN2KIUyUMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/index.vue?macro=true";
import { default as infosufbzTBL1fMeta } from "/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/info.vue?macro=true";
export default [
  {
    name: "400",
    path: "/400",
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/400.vue").then(m => m.default || m)
  },
  {
    name: "500",
    path: "/500",
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/500.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "credor-casas-bahia",
    path: "/credor/casas-bahia",
    meta: casas_45bahianzBQp2bdgDMeta || {},
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/credor/casas-bahia.vue").then(m => m.default || m)
  },
  {
    name: "credor-digio",
    path: "/credor/digio",
    meta: digioCEAIFQzkpdMeta || {},
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/credor/digio.vue").then(m => m.default || m)
  },
  {
    name: "credor-ponto",
    path: "/credor/ponto",
    meta: ponto8E3hPGe2G1Meta || {},
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/credor/ponto.vue").then(m => m.default || m)
  },
  {
    name: "empresa",
    path: "/empresa",
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/empresa.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexWFlN2KIUyUMeta || {},
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/codebuild/output/src2590706906/src/melembre-landing-page-v2/pages/info.vue").then(m => m.default || m)
  }
]